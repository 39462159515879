import * as React from "react";
import { Provider } from "react-redux";
import store from "../../store";
import List from "./list";

export default class FavoriteRoomsSimpleList extends React.Component {
  public render() {
    return (
      <Provider store={store()}>
        <List />
      </Provider>
    );
  }
}
