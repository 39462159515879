import * as React from "react";
import { Provider } from "react-redux";
import store from "../../store";
import Container from "./container";

interface Props {
  apiKey: string;
  language?: string;
}

const BuildingsMap: React.FunctionComponent<Props> = props => (
  <Provider store={store()}>
    <Container apiKey={props.apiKey} language={props.language || "ja"} />
  </Provider>
);

export default BuildingsMap;
