import * as numeral from "numeral";
import * as React from "react";
import { compose, pure, setDisplayName } from "recompose";

interface Props {
  price: number | string;
}

const Price: React.FunctionComponent<Props> = ({ price }) => (
  <>
    {typeof price !== "number" && <span>{price}</span>}
    {price === 0 && <span>-</span>}
    {typeof price === "number" && price !== 0 && (
      <>
        <span>{numeral(price).format("0,0")}</span>円/月
      </>
    )}
  </>
);

export default compose<Props, Props>(
  pure,
  setDisplayName("Price")
)(Price);
