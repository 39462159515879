import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions";
import fetchFavoriteItems from "../business_logics/fetchFavoriteItems";
import Room from "../models/Room";

type Action = ActionType<typeof actions>;

export interface FavoriteState {
  readonly items: number[];
  readonly rooms: Room[];
}

export const initialState: FavoriteState = {
  items: fetchFavoriteItems(),
  rooms: []
};

export default (state: FavoriteState = initialState, action: Action) => {
  switch (action.type) {
    case getType(actions.favorite.receive): {
      return { ...state, rooms: action.payload };
    }
    case getType(actions.favorite.sort): {
      const newRooms: Room[] = [];
      state.items.forEach(item => {
        const room = state.rooms.find(r => r.id === item);
        if (room) {
          newRooms.push(room);
        }
      });
      return { ...state, rooms: newRooms };
    }
    case getType(actions.favorite.add): {
      if (state.items.indexOf(action.payload) < 0) {
        return { ...state, items: state.items.concat(action.payload) };
      } else {
        return state;
      }
    }
    case getType(actions.favorite.remove): {
      return {
        ...state,
        items: state.items.filter(item => item !== action.payload)
      };
    }
    case getType(actions.favorite.bringForward): {
      const newItems = state.items.concat([]);
      const idx = newItems.indexOf(action.payload);
      if (idx <= 0) {
        return state;
      } else {
        newItems.splice(idx - 1, 2, newItems[idx], newItems[idx - 1]);
        return { ...state, items: newItems };
      }
    }
    case getType(actions.favorite.bringBackward): {
      const newItems = state.items.concat([]);
      const idx = newItems.indexOf(action.payload);
      if (idx < 0 || idx === newItems.length - 1) {
        return state;
      } else {
        newItems.splice(idx, 2, newItems[idx + 1], newItems[idx]);
        return { ...state, items: newItems };
      }
    }
    default:
      return state;
  }
};
