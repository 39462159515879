import { LoadScript } from '@react-google-maps/api';
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import { AnyAction, Dispatch } from "redux";
import styled from "styled-components";
import * as actions from "../../actions";
import constants from '../../constants';
import i18n from "../../locales/i18n";
import { BuildingSearchState } from "../../states/buildingSearch";
import { AppState } from "../../store";
import List from "./list";
import Map from "./map";

const Wrapper = styled.div`
  margin-top: 1rem;

  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    margin-top: 4px;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

interface InnerProps {
  buildingSearch: BuildingSearchState;
  requestFetchBuildings: () => void;
}

interface OuterProps {
  apiKey: string;
  language: string;
}

type Props = InnerProps & OuterProps

const BuildingsContainer: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const [perPage, setPerPage] = React.useState<number>(30);

  React.useEffect(() => {
    props.requestFetchBuildings();
  }, []);

  React.useEffect(() => {
    const el = document.querySelector("#searched-buildings-count");
    if (el) {
      const { buildings, isFetching, pager } = props.buildingSearch;
      let html = "";
      if (isFetching) {
        html = `<img src=${constants.images.loading} style="height:32px;width:auto;"/>`;
      } else if (!isFetching && buildings.length > 0) {
        const from = (pager!.currentPage - 1) * pager!.perPage + 1;
        const to = from - 1 + buildings.length;
        const n = `${from}〜${to}`;
        html = `<span style="font-size: ${i18n.language === "ja" ? "inherit" : "13px"};">${t("件を表示", { n })}／</span><span class="building-search__building-count-number">${pager!.total}</span><span style="font-size: ${i18n.language === "ja" ? "inherit" : "13px"};">${t("件中")}</span>`;
      }
      el.innerHTML = html;
    }
  }, [props.buildingSearch.isFetching, props.buildingSearch.buildings]);

  React.useEffect(() => {
    const { pager } = props.buildingSearch;
    if (pager) {
      const params = new URLSearchParams(window.location.search);
      if (parseInt(params.get("per_page") || "25", 10) !== perPage) {
        params.set("per_page", perPage.toString());
        params.set("page", "1");
        history.replaceState(null, document.title, `${window.location.pathname}?${params.toString()}`);
        props.requestFetchBuildings();
      }
    }
  }, [perPage]);

  const renderContents = () => {
    if (props.buildingSearch.isFetching) {
      return <div className="mt-3 has-text-centered	"><img src={constants.images.loading} /></div>
    } else if (props.buildingSearch.buildings.length === 0) {
      return (<div className="message is-danger mt-3">
        <div className="message-header">{t("条件に合うオフィスが見つかりません")}</div>
        <div className="message-body">{t("エリアやスペースタイプを変更して再度検索してください")}</div>
      </div>)
    } else {
      return (
        <>
          <div className="building-search__map-box">
            <List />
            <Map />
          </div>
          <Wrapper className="is-clearfix">
            <div className="is-pulled-right">
              <Inner>
                <span>{t("表示件数")}&nbsp;</span>
                <div className="select">
                  <select value={perPage} onChange={(e) => setPerPage(parseInt(e.target.value, 10))}>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span>&nbsp;{t("件")}</span>
              </Inner>
            </div>
          </Wrapper>
        </>
      )
    }
  };

  return (
    <LoadScript googleMapsApiKey={props.apiKey} language={props.language}>{renderContents()}</LoadScript>
  );
}

const mapStateToProps = (state: AppState) => ({
  buildingSearch: state.buildingSearch
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  requestFetchBuildings: () => dispatch(actions.buildingSearch.fetch())
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  BuildingsContainer
);
