import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import { AnyAction, Dispatch } from "redux";
import styled from "styled-components";
import * as actions from "../../actions";
import EmptyFavoriteRooms from "../../components/molecules/EmptyFavoriteRooms";
import List from "../../components/organisms/FavoriteRoomsSimpleList";
import i18n from "../../locales/i18n";
import Room from "../../models/Room";
import { AppState } from "../../store";

const Link = styled.a`
  background-color: rgb(7, 32, 116);
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${i18n.language === "ja" ? "1rem" : "12px"};
  font-weight: bold;
  height: 60px;
  width: 100%;
  margin: 15px auto 0;

  &:hover {
    color: #fff;
    opacity: 0.7;
  }
`;

interface Props {
  favorites: number[];
  rooms: Room[];
  requestFetchRooms: () => void;
}

const FavoriteRoomsSimpleList: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  React.useEffect(() => {
    props.requestFetchRooms();
  }, []);

  const { favorites, rooms } = props;
  if (favorites.length === 0) {
    return <EmptyFavoriteRooms />
  } else {
    return (
      <>
        <List rooms={rooms} />
        <Link href="/favorites">{t("検討オフィス一覧を見る")}</Link>
      </>
    );
  }
};

const mapStateToProps = (state: AppState) => ({
  favorites: state.favorite.items,
  rooms: state.favorite.rooms
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  requestFetchRooms: () => dispatch(actions.favorite.fetch()),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FavoriteRoomsSimpleList);
