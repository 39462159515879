import request from "../request";

type FetchBuildingParams = string;
interface FetchBuildingRequest {
  lang: string;
  q: string | undefined;
}

const fetchBuildingsByName = async (query: FetchBuildingParams) => {
  const url = "/buildings/names";
  const params: FetchBuildingRequest = {
    lang: /lang=en/.test(window.location.search) ? "en" : "ja",
    // tslint:disable-next-line:object-literal-sort-keys
    q: undefined
  };

  params.q = query;
  return request({
    baseURL: "/api/v1",
    method: "get",
    params,
    url
  });
};
export default fetchBuildingsByName;
