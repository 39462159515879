import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import fetchLatest from "../api/versions/fetchLatest";

function* handleFetchLatestVersion() {
  try {
    const data = yield call(fetchLatest);
    yield put(actions.version.receive(data));
  } catch (error) {
    yield put(actions.version.receive(error));
  }
}

function* watchVersionRequest() {
  yield takeLatest(
    actions.version.fetchLatest().type,
    handleFetchLatestVersion
  );
}

export { handleFetchLatestVersion, watchVersionRequest };
