import { useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import { Area } from "react-easy-crop";
import styled from "styled-components";
import CropperModal from "./CropperModal";
import DropImageZone from "./DropImageZone";
import getCroppedImg from "./getCroppedImage";
import InputImage from "./InputImage";
import { useGetImageUrl } from "./useGetImageUrl";

const BaseLabel = styled.label`
  display: flex;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
`;


interface InnerProps {
  width: number;
  height: number;
  formWidth: number;
  uuid: string;
}

const AttachmentForm: React.FC<InnerProps> = ({
  width,
  height,
  uuid,
  formWidth,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const [isOverSize, setIsOverSize] = useState(false);
  const [croppedImgSrc, setCroppedImgSrc] = useState("");
  const [attachmentId, setAttachmentId] = useState<string>('');
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget.files[0]) {
      const targetFile = e.currentTarget.files[0];
      setImageFile(targetFile);
    }
  };

  const onDropFile = (file: File) => {
    if (file.type.substring(0, 5) !== "image") {
      alert("画像ファイルでないものはアップロードできません。");
    } else {
      setImageFile(file);
    }
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, newCroppedAreaPixels: Area) => {
      setCroppedAreaPixels(newCroppedAreaPixels);
    },
    []
  );
  const { imageUrl } = useGetImageUrl({file: imageFile});
  const handleClickCancelButton = () => {
    setImageFile(null);
    setCroppedImgSrc("");
    setIsOverSize(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleSubmit = (isDisabled: boolean) => {
    const submitButton = document.getElementById('submit') as HTMLInputElement;
    submitButton.disabled = isDisabled;
  }
  const postImage = async (file: File) => {
    const url = '/owner/attachments';
    const token = document.getElementsByName('csrf-token')[0].getAttribute('content');
    if (!token) {
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(url, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
      headers:{
        'X-CSRF-Token': token
      }
    });
    const result = await response.json();
    setAttachmentId(result.id);
    handleSubmit(false);
  }

  const showCroppedImage = useCallback(async () => {
    if (!croppedAreaPixels || !imageUrl) {
      return;
    }
    try {
      const data = await getCroppedImg(
        imageUrl,
        croppedAreaPixels,
        width,
        height,
      );
      if (data === null) {
        return;
      }
      const imgFile = new File(
        [data.file],
        `img-${Date.now()}.jpeg`,
        {
          type: 'image/jpeg'
        },
      );
      setCroppedImgSrc(data.url);
      setIsOverSize(false);
      setImageFile(imgFile);
      // canSubmit();
    } catch (e) {
      alert('画像のトリミングに失敗しました。');
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        if (img.height < height || img.width < width) {
          setImageFile(null);
          alert(`幅: ${width}px以上、高さ: ${height}px以上の写真を選択してください`)
          return;
        }
        if (img.height !== height || img.width !== width) {
          handleSubmit(true);
          setIsOverSize(true);
        } else {
          setIsOverSize(false);
          if (imageFile) {
            postImage(imageFile);
          }
        }
      }
    }
  }, [imageUrl]);
  return (
    <>
      <DropImageZone onDropFile={onDropFile}>
      <BaseLabel
        htmlFor={uuid}
        style={{
          border: isOverSize ? "red 1px dotted": "black 1px dotted",
          height: formWidth / (width / height),
          width: formWidth,
        }}
      >
        <input
          type={'hidden'}
          name={`attachment_id_${uuid}`}
          value={attachmentId}
        />
        {imageUrl && imageFile ? (
          <img
            src={croppedImgSrc === "" ? imageUrl : croppedImgSrc}
            alt="アップロード画像"
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            onClick={() => {
              if (croppedImgSrc === "") {
                setIsOpen(true);
              }
            }}
          />
        ) : (
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 16,}}>
            <span className="icon" style={{marginBottom: 16}}>
              <i className="fas fa-upload fa-2x" />
            </span>
            アップロードするファイルをここにドロップしてください
          </div>
        )}
        {imageUrl && imageFile && isOverSize ? (
          <CropperModal
            crop={crop}
            setCrop={setCrop}
            onCropComplete={onCropComplete}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            imgSrc={croppedImgSrc === "" ? imageUrl : croppedImgSrc}
            showCroppedImage={showCroppedImage}
            width={width}
            height={height}
          />
        ) : (
          <InputImage
            ref={fileInputRef}
            id={uuid}
            onChange={handleFileChange}
          />
        )}
      </BaseLabel>
      </DropImageZone>
      <div style={{display: 'flex', flexDirection: 'column'}}>
      {isOverSize && (
        <span style={{color: 'red', fontSize: 12}}>
          トリミングが必要です。画像をクリックしてください。
        </span>
      )}
      <a onClick={handleClickCancelButton} style={{fontSize: 12}}>
        キャンセル
      </a>
    </div>
    </>
  );
};

export default AttachmentForm;
