import * as React from "react";
import AttachmentForm from "./form";

interface Props {
  width: number;
  height: number;
  formWidth: number;
  uuid: string;
  imageType: string;
}

const ValidationAttachment: React.FC<Props> = ({
  width = 3200,
  height = 1800,
  formWidth = 300,
  uuid,
}) => {
  return (
    <AttachmentForm
      width={width}
      height={height}
      formWidth={formWidth}
      uuid={uuid}
    />
  );
}

export default ValidationAttachment;
