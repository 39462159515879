import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import { AnyAction, Dispatch } from "redux";
import styled from "styled-components";
import * as actions from "../../actions";
import constants from "../../constants";
import Building from "../../models/Building";
import { BuildingSearchState } from "../../states/buildingSearch";
import { AppState } from "../../store";

const Wrapper = styled.div`
  position: relative;
  flex-grow: 1;

  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    width: 100%;
    &:after {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
`;

const Refresh = styled.button`
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #192340;
  cursor: pointer;
  outline: none;
  padding: 5px 10px;
  color: #003f96;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    padding: 2px 5px;
  }
`;

const Inner = styled.a`
  color: #333;
  &:hover {
    color: #363636;
  }
`;

const Pr = styled.div`
  background-color: #eaeff6;
  color: #0a3589;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  height: 25px;
  line-height: 24px;
  padding: 0 10px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 12px;

  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
      display: none;
  }
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;

  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
      display: none;
  }
`;

const Ml1 = styled.div`
  margin-left: 10px;
`;

const Title = styled.h4`
  color: ${constants.colors.darkBlue};
  font-size: 14px;
  font-weight: 600;
  margin-top: 3px;
`;

const Description = styled.div`
  font-size: 14px;
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 1px;
  margin-top: 10px;
`;

const Th = styled.th`
  background-color: #dbe3f0;
  color: ${constants.colors.darkBlue};
  font-size: 12px;
  font-weight: normal;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
`;

const Td = styled.td`
  background-color: #eaeff6;
  font-size: 12px;
  font-weight: normal;
  padding: 8px;
`;

const Tag = styled.a`
  color: #333;
  font-size: 12px;
  font-weight: normal;
  margin-right: 1em;
  text-decoration: underline;

  &:hover {
    color: ${constants.colors.brand};
    text-decoration: none;
  }
`;

interface Props {
  buildingSearch: BuildingSearchState;
  activateBuilding: (building?: Building) => void;
  requestFetchBuildings: () => void;
}

const BuildingsMap: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const [pins, setPins] = React.useState<google.maps.Icon[]>([]);
  const [gMap, setMap] = React.useState<google.maps.Map | null>(null);
  const [offset, setOffset] = React.useState<google.maps.Size | null>(null);

  const onLoad = React.useCallback((map: google.maps.Map) => {
    const bounds = new google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
    setPins([
      { url: "https://jfo-app.s3-ap-northeast-1.amazonaws.com/assets/images/pin.png", scaledSize: (new google.maps.Size(30, 42)) },
      { url: "https://jfo-app.s3-ap-northeast-1.amazonaws.com/assets/images/pin_on.png", scaledSize: (new google.maps.Size(45, 62)) },
      { url: "https://jfo-app.s3-ap-northeast-1.amazonaws.com/assets/images/pin_pr.png", scaledSize: (new google.maps.Size(30, 42)) },
      { url: "https://jfo-app.s3-ap-northeast-1.amazonaws.com/assets/images/pin_pr_on.png", scaledSize: (new google.maps.Size(45, 62)) },
    ]);
    setOffset(new google.maps.Size(0, -76))
  }, [])

  const onUnmount = React.useCallback(() => {
    setMap(null)
  }, [])

  React.useEffect(() => {
    const { buildings } = props.buildingSearch;
    if (gMap && buildings.length > 0) {
      // setTimeout is workaround
      setTimeout(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has("latitude") && params.has("longitude")) {
          gMap.setCenter({ lat: parseFloat(params.get("latitude")!), lng: parseFloat(params.get("longitude")!) });
        } else {
          gMap.setCenter(latLng(buildings[0]));
        }
        gMap.setZoom(14);
      }, 1000);
    }
  }, [gMap, props.buildingSearch.buildings]);

  const latLng = (building: Building) => ({ lat: building.latitude!, lng: building.longitude! });

  const selectPin = (building: Building) => {
    if (building.isPr === true) {
      return building.id === props.buildingSearch.activeBuilding?.id ? pins[3] : pins[2];
    }
    return building.id === props.buildingSearch.activeBuilding?.id ? pins[1] : pins[0];
  };

  const handleSearch = () => {
    if (gMap) {
      const center = gMap.getCenter();
      if (center) {
        const params = new URLSearchParams(window.location.search);
        params.set("latitude", `${center.lat()}`);
        params.set("longitude", `${center.lng()}`);
        params.set("distance", "3000");
        params.delete("building_area[area_category_ids][]")
        history.replaceState(null, document.title, `${window.location.pathname}?${params.toString()}`);
        props.requestFetchBuildings();
      }
    }
  };

  const renderInfoWindow = (building: Building) => (<InfoWindow position={latLng(building)} onCloseClick={() => props.activateBuilding(undefined)} options={{ pixelOffset: offset }} key={`info-window-${building.id}`}>
    <Inner href={`/buildings/${building.id}`} target="_blank">
      {building.isPr && <Pr>PR</Pr>}
      <Head>
        <img src={building.image} width={85} />
        <Ml1>
          <ul className="space-type__list">
            {building.occupyTypes.map(occupyType => (<li className="space-type__item--is-small" key={`${building.id}-${occupyType}`}><span>{occupyType}</span></li>))}
          </ul>
          <Title>{building.name}</Title>
          <Description>{building.monthlyRent}</Description>
        </Ml1>
      </Head>
      <Table>
        <tbody>
          <tr>
            <Th>{t("住所")}</Th>
            <Td>{building.address}</Td>
          </tr>
          <tr>
            <Th>{t("最寄駅")}</Th>
            <Td>{building.nearestStation}</Td>
          </tr>
          <tr>
            <Th>{t("特徴")}</Th>
            <Td>
              {building.tags?.map(tag => (<Tag href={tag.url} key={`${building.id}-${tag.name}`}>{t(tag.name)}</Tag>))}
            </Td>
          </tr>
        </tbody>
      </Table>
    </Inner>
  </InfoWindow>);

  return (
    <Wrapper>
      <Refresh onClick={handleSearch}>
        <span className="icon">
          <i className="fas fa-search fa-sm" />
        </span>
        <span>{t("このエリアで再検索")}</span>
      </Refresh>
      <GoogleMap
        mapContainerStyle={{ width: "auto", height: "100%" }}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerClassName="building-search__map-box-map"
        options={{ mapTypeControl: false, styles: constants.GOOGLE_MAPS_CUSTOM_STYLE }}
      >
        {[...props.buildingSearch.buildings, ...props.buildingSearch.prBuildings].map(b => (
          <React.Fragment key={`map-content-${b.id}-${b.isPr ? "pr" : "no-pr"}`}>
            <Marker key={`marker-${b.id}`} position={latLng(b)} icon={selectPin(b)} onClick={() => props.activateBuilding(b)} />
            {b.id === props.buildingSearch.activeBuilding?.id && renderInfoWindow(b)}
          </React.Fragment>
        ))}
      </GoogleMap>
    </Wrapper>
  );
}

const mapStateToProps = (state: AppState) => ({
  buildingSearch: state.buildingSearch
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  activateBuilding: (building?: Building) => dispatch(actions.buildingSearch.activate(building)),
  requestFetchBuildings: () => dispatch(actions.buildingSearch.fetch())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BuildingsMap
);
