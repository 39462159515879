import * as cookies from "js-cookie";
import * as store from "store2";
import Constants from "../constants";

export default (items: number[]) => {
  store(Constants.KEY_STORED_FAVORITE_ITEMS, items);
  String(items) === ""
    ? cookies.remove(Constants.KEY_STORED_FAVORITE_ITEMS)
    : cookies.set(Constants.KEY_STORED_FAVORITE_ITEMS, String(items), {
        expires: 365
      });
};
