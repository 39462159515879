import axios from "axios";

const csrf = document.querySelector('[name="csrf-token"]');
let token = null;
if (csrf) {
  token = csrf.getAttribute("content");
}

const client = axios.create({
  baseURL: "/api/v1",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": token,
    "X-Requested-With": "XMLHttpRequest"
  },
  withCredentials: true
});

export default client;
