import request from "../request";

type FetchBuildingParams = number[] | string;
interface FetchBuildingRequest {
  lang: string;
  ids: number[] | undefined;
  q: string | undefined;
}

const fetchBuildings = async (query: FetchBuildingParams) => {
  const url = "/buildings";
  const params: FetchBuildingRequest = {
    lang: /lang=en/.test(window.location.search) ? "en" : "ja",
    // tslint:disable-next-line:object-literal-sort-keys
    ids: undefined,
    q: undefined
  };
  if (Array.isArray(query)) {
    params.ids = query;
  } else {
    params.q = query;
  }

  return request({
    baseURL: "/api/v1",
    method: "get",
    params,
    url
  });
};
export default fetchBuildings;
