import "core-js/stable";
import "regenerator-runtime/runtime";
import WebpackerReact from "webpacker-react";
import LazyLoad from "vanilla-lazyload";
import Carousel from "components/molecules/Carousel";
import FavoriteRoomsSimpleList from "containers/FavoriteRoomsSimpleList";
import PageHistoriesList from "containers/PageHistoriesList";
import KeywordSearch from "containers/KeywordSearch";
import ValidationAttachment from "containers/ValidationAttachment";
import BuildingsMap from "containers/BuildingsMap";
import Glider from "glider-js/glider";
import savePageHistoryItems from "../business_logics/savePageHistoryItems";

WebpackerReact.setup({
  Carousel,
  FavoriteRoomsSimpleList,
  PageHistoriesList,
  KeywordSearch,
  BuildingsMap,
  ValidationAttachment
});

document.addEventListener("DOMContentLoaded", event => {
  new LazyLoad({
    elements_selector: "img[data-lazyload]"
  });

  var el = document.querySelector("#pickups-for-map");
  if (el) {
    new LazyLoad({
      container: el,
      elements_selector: "#pickups-for-map img[data-lazyload]"
    });
  }

  var gliders = document.querySelectorAll(".glider");
  gliders.forEach(g => {
    new Glider(g, {
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: {
        prev: ".glider-prev",
        next: ".glider-next"
      },
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 8
          }
        }
      ]
    });
  });

  const saveHistory = () => {
    const BUILDING_PATTERN = /^\/buildings\/([0-9]+)$/;
    const ROOM_PATTERN = /^\/buildings\/([0-9]+)\/rooms\/([0-9]+)$/;
    let path = window.location.pathname;
    // for IE
    if (/^\//.test(path) === false) {
      path = `/${path}`;
    }
    const roomMatch = path.match(ROOM_PATTERN);
    const buildingMatch = path.match(BUILDING_PATTERN);
    if (roomMatch) {
      const history = {
        building: parseInt(roomMatch[1], 10),
        room: parseInt(roomMatch[2], 10)
      };
      savePageHistoryItems(history);
    } else if (buildingMatch) {
      const history = {
        building: parseInt(buildingMatch[1], 10)
      };
      savePageHistoryItems(history);
    }
  };
  saveHistory();
});
