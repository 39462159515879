import * as React from "react";
import { useTranslation } from "react-i18next";
import { compose, pure, setDisplayName } from "recompose";
import styled from "styled-components";
import Room from "../../../models/Room";
import Price from "./Price";

const Wrap = styled.div`
  min-height: 120px;
  display: flex;
  flex-direction: column;
  background-color: #eaeff4;
  position: relative;

  &:not(:last-child) {
    &:after {
      content: "";
      display: block;
      position: absolute;
      border-bottom: 1px solid #fff;
      bottom: 0;
      left: 15px;
      right: 15px;
    }
  }
`;

const Link = styled.a`
  padding: 15px 15px;
`;

const Title = styled.h4`
  color: #003f96;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Name = styled.div`
  color: #333333;
  font-size: 14px;
  font-weight: 700;
`;

const Info = styled.div`
  color: #333;
  font-size: 14px;
`;

interface Props {
  room: Room;
}

const FavoriteRoomsSimpleListItem: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Link href={`/buildings/${props.room.buildingId}`}>
        <Title>{props.room.buildingName}</Title>
        <Content>
          <img className="is-hidden-mobile" height="313" width="85" src={props.room.image} />
          <img className="is-hidden-tablet" height="160" width="241" src={props.room.image} />
          <Description>
            <Name>{props.room.name}</Name>
            <Info><Price price={props.room.monthlyRent || 0} />{`(${props.room.maxCapacity || 1}${t("名")})`}</Info>
          </Description>
        </Content>
      </Link>
    </Wrap>
  );
};

export default compose<Props, Props>(
  pure,
  setDisplayName("FavoriteRoomsSimpleListItem")
)(FavoriteRoomsSimpleListItem);
