import * as React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { AnyAction, Dispatch } from "redux";
import styled from "styled-components";
import * as actions from "../../actions";
import constants from "../../constants";
import Building from "../../models/Building";
import { BuildingSearchState } from "../../states/buildingSearch";
import { AppState } from "../../store";

const Container = styled.div`
  width: 280px;
  max-width: 50%;

  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    margin-top: 10px;
    margin-left: -18px;
    margin-right: -18px;
    max-width: none;
    width: 100vw;
  }
`;

const Wrapper = styled.div`
  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    display: flex;
    flex-direction: row;
  }
`;

const ButtonStyle = `
    background-color: #8193bd;
    border: 0;
    display: none;
    flex-shrink: 0;
    position: relative;
    width: 30px;

    @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
      display: block;
    }

    &:after {
      border: 2px solid #fff;
      border-radius: 2px;
      content: " ";
      display: block;
      height: 0.625em;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: rotate(45deg);
      transform-origin: center;
      width: 0.625em;
    }
`;

const Prev = styled.button`
  ${ButtonStyle}
  &:after {
    border-right: 0;
    border-top: 0;
    margin-top: -0.4375em;
    margin-left: 0.3375em;
  }
`;

const Next = styled.button`
  ${ButtonStyle}
  &:after {
    border-left: 0;
    border-bottom: 0;
    margin-top: -0.4375em;
    margin-left: 0.1375em;
  }
`;

const Items = styled.div`
  overflow-x: auto;
`;

const List = styled.ul`
  height: 730px;
  max-height: 730px;
  overflow-y: auto;

  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    display: flex;
    flex-direction: row;
    height: auto;
    max-height: none;
  }
`;

const ListItem = styled.li`
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  width: 100%;

  &:not(:last-child) {
    &:after {
      content: "";
      display: block;
      position: absolute;
      border-bottom: 1px solid #fff;
      bottom: 0;
      left: 15px;
      right: 15px;

      @media only screen and (max-width: ${constants.breakpoints.sm}px),
        (max-device-width: ${constants.breakpoints.sm}px) {
        display: none;
      }
    }
  }

  ${(props: { isPr?: boolean }) =>
    props.isPr === true &&
    `
      background-color: #dde8fc;
    `}
`;

const PrTag = styled.div`
  background-color: #fff;
  content: "PR";
  color: #0a3589;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  float: right;
  height: 20px;
  line-height: 1;
  padding: 0 10px;
  position: absolute;
  right: 15px;
  top: 12px;
  text-align: center;
  width: 40px;
`;

const PagerDesktop = styled.div`
  display: block;
  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    display: none;
  }
`;

const PagerMobile = styled.div`
  display: none;
  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    display: block;
  }
`;

const Pager = styled.li`
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    background-color: transparent;
  }
`;

const Mb5px = styled.div`
  margin-bottom: 5px;
`;

const Body = styled.a`
  padding: 10px 15px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const H4 = styled.h4`
  color: #0a3589;
  font-size: 14px;
  font-weight: 700;
  word-break: break-all;
`;

const Price = styled.div`
  color: #333;
  font-size: 14px;
`;

interface Props {
  buildingSearch: BuildingSearchState;
  activateBuilding: (building?: Building) => void;
  requestFetchBuildings: () => void;
}

const BuildingsList: React.FunctionComponent<Props> = props => {
  const [itemIndex, setItemIndex] = React.useState<number | null>(null);
  const scroller = React.createRef<any>();

  React.useEffect(() => {
    if (scroller.current && itemIndex !== null) {
      scroller.current.scrollTo({
        left: scroller.current.clientWidth * itemIndex,
        // tslint:disable-next-line:object-literal-sort-keys
        behavior: "smooth"
      });
      const activeBuilding = [
        ...props.buildingSearch.prBuildings,
        ...props.buildingSearch.buildings
      ][itemIndex];
      props.activateBuilding(activeBuilding);
    }
  }, [itemIndex]);

  React.useEffect(() => {
    const { activeBuilding } = props.buildingSearch;
    if (activeBuilding) {
      const buildingIds = [
        ...props.buildingSearch.prBuildings,
        ...props.buildingSearch.buildings
      ].map(b => b.id);
      setItemIndex(buildingIds.indexOf(activeBuilding.id));
    }
  }, [props.buildingSearch.activeBuilding]);

  const incrementItem = () => {
    let index = itemIndex;
    if (index === null) {
      setItemIndex(0);
      index = 0;
    }
    const max =
      props.buildingSearch.prBuildings.length +
      props.buildingSearch.buildings.length -
      1;
    if (index < max) {
      setItemIndex(index + 1);
    }
  };

  const decrementItem = () => {
    if (itemIndex !== null && itemIndex > 0) {
      setItemIndex(itemIndex - 1);
    }
  };

  const handleSwipe = () => {
    if (scroller.current) {
      setTimeout(() => {
        setItemIndex(
          Math.round(
            scroller.current!.scrollLeft / scroller.current!.clientWidth
          )
        );
      }, 500);
    }
  };

  const handlePrev = () => {
    const { pager } = props.buildingSearch;
    if (pager) {
      const params = new URLSearchParams(window.location.search);
      params.set("page", (pager.currentPage - 1).toString());
      history.replaceState(null, document.title, `${window.location.pathname}?${params.toString()}`);
      props.requestFetchBuildings();
    }
  };

  const handleNext = () => {
    const { pager } = props.buildingSearch;
    if (pager) {
      const params = new URLSearchParams(window.location.search);
      params.set("page", (pager.currentPage + 1).toString());
      history.replaceState(null, document.title, `${window.location.pathname}?${params.toString()}`);
      props.requestFetchBuildings();
    }
  };

  const handleItemClick = (building: Building) => {
    if (window.screen.width > constants.breakpoints.sm) {
      props.activateBuilding(building)
    } else {
      window.open(`/buildings/${building.id}`, "_blank");
    }
  }

  const renderPager = () => (
    <Pager>
      {props.buildingSearch.pager && props.buildingSearch.pager.currentPage > 1 && (
        <div>
          <span>&lt;&nbsp;</span><a href={void (0)} onClick={handlePrev}>{`前の${props.buildingSearch.pager.perPage}件`}</a>
        </div>
      )}
      {props.buildingSearch.pager && props.buildingSearch.pager.currentPage < props.buildingSearch.pager.maxPage && (
        <div>
          <a href={void (0)} onClick={handleNext}>{`次の${props.buildingSearch.pager.perPage}件`}</a><span>&nbsp;&gt;</span>
        </div>
      )}
    </Pager>
  );

  return (
    <Container>
      <Wrapper>
        <Prev onClick={decrementItem} />
        <Items>
          <List innerRef={scroller}>
            {[
              ...props.buildingSearch.prBuildings,
              ...props.buildingSearch.buildings
            ].map(b => (
              <ListItem
                onTouchEnd={handleSwipe}
                isPr={b.isPr}
                key={`list-${b.id}-${b.isPr ? "pr" : "no-pr"}`}
              >
                <Body href={void (0)} onClick={() => handleItemClick(b)}>
                  <Mb5px>
                    <div className="is-clearfix">
                      <ul className="space-type__list" style={{ minHeight: b.isPr ? "18px" : "auto", marginLeft: "-10px", float: "left", width: "calc(100% - 40px)" }}>
                        {b.occupyTypes.map(o => (
                          <li
                            className="space-type__item--is-small"
                            key={`list-occupy-type-${o}`}
                            style={{ marginLeft: "5px" }}
                          >
                            <span>{o}</span>
                          </li>
                        ))}
                      </ul>
                      {b.isPr && <PrTag>PR</PrTag>}
                    </div>
                  </Mb5px>
                  <Content>
                    <img
                      className="is-hidden-mobile"
                      src={b.image}
                      height="auto"
                      width="90"
                    />
                    <img
                      className="is-hidden-tablet"
                      src={b.image}
                      height="auto"
                      width="96"
                    />
                    <Description>
                      <H4>{b.name}</H4>
                      <Price>{b.monthlyRent}</Price>
                    </Description>
                  </Content>
                </Body>
              </ListItem>
            ))}
            <PagerDesktop>{renderPager()}</PagerDesktop>
          </List>
        </Items>
        <Next onClick={incrementItem} />
      </Wrapper>
      <PagerMobile>{renderPager()}</PagerMobile>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  buildingSearch: state.buildingSearch
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  activateBuilding: (building?: Building) =>
    dispatch(actions.buildingSearch.activate(building)),
  requestFetchBuildings: () => dispatch(actions.buildingSearch.fetch())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BuildingsList
);
