import { createStandardAction } from "typesafe-actions";
import Room from "../models/Room";

const FETCH = "FAVORITE/FETCH";
const RECEIVE = "FAVORITE/RECEIVE";
const SORT = "FAVORITE/SORT";
const ADD = "FAVORITE/ADD";
const REMOVE = "FAVORITE/REMOVE";
const BRING_FORWARD = "FAVORITE/BRING_FORWARD";
const BRING_BACKWARD = "FAVORITE/BRING_BACKWARD";

export const fetch = createStandardAction(FETCH)();
export const receive = createStandardAction(RECEIVE)<Room[]>();
export const sort = createStandardAction(SORT)();
export const add = createStandardAction(ADD)<number>();
export const remove = createStandardAction(REMOVE)<number>();
export const bringForward = createStandardAction(BRING_FORWARD)<number>();
export const bringBackward = createStandardAction(BRING_BACKWARD)<number>();
