import * as numeral from "numeral";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import nl2br from "react-nl2br";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import { compose } from "recompose";
import { AnyAction, Dispatch } from "redux";
import styled from "styled-components";
import * as actions from "../../actions";
import constants from "../../constants";
import i18n from "../../locales/i18n";
import Building from "../../models/Building";
import Room from "../../models/Room";
import { PageHistoryState } from "../../states/pageHistory";
import { AppState } from "../../store";
import { PageHistory } from "../../types/PageHistory";

const StyledPopup = styled(Popup)`
  &-content {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 !important;
    width: 75% !important;
    @media only screen and (max-width: ${constants.breakpoints.sm}px),
      (max-device-width: ${constants.breakpoints.sm}px) {
      width: 100% !important;
    }
  }
`;

const PopupBody = styled.div`
  position: relative;
`;

const ModalBody = styled.section`
  padding: 1rem;
  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    padding: 1rem 0.5rem;
  }
`;

const CloseButton = styled.button`
  width: 100%;
`;

const Row = styled.div`
  & + & {
    margin-top: 3rem;
  }
`;

const BuildingName = styled.a`
  color: ${constants.colors.brand} !important;
  font-size: 24px;
  text-decoration: underline;
  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    font-size: 18px;
  }
`;

const Dd = styled.dd`
  padding-bottom: 10px !important;
`;

interface Props {
  pageHistory: PageHistoryState;
  requestFetchPageHistories: () => void;
}

const PageHistoriesList: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  useEffect(() => {
    props.requestFetchPageHistories();
  }, []);

  const renderButton = () => {
    return (
      <a onClick={props.requestFetchPageHistories}>
        <span className="is-hidden-mobile">
          {t("閲覧履歴")}&nbsp;
          <i className="fas fa-caret-down" />
        </span>
        <span className="is-hidden-tablet">
          <i className="fas fa-history" />
        </span>
      </a>
    );
  };

  const renderBuilding = (building: Building) => {
    return (
      <Row key={`building-${building.id}`}>
        <div className="columns">
          <div className="column">
            <BuildingName href={`/buildings/${building.id}`}>
              {building.name}
            </BuildingName>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-quarters">
            {building.image && <img src={building.image} width="100%" />}
          </div>
          <div className="column is-three-quarters">
            <div className="columns is-gapless">
              <div className="column">
                <dl className="building-search__building-info-data">
                  <dt>{t("住所")}</dt>
                  <Dd>
                    {building.address}
                    <a
                      href={`/buildings/${building.id}#environment_info`}
                      className="building-search__building-map-link"
                      style={{ color: "#fff" }}
                    >
                      MAP
                    </a>
                  </Dd>
                </dl>
              </div>
              <div className="column">
                <dl className="building-search__building-info-data">
                  <dt>{t("最寄駅")}</dt>
                  <Dd>{nl2br(building.nearestStation)}</Dd>
                </dl>
              </div>
              <div className="column">
                <dl className="building-search__building-info-data">
                  <dt>{t("施工")}</dt>
                  <Dd>{building.age}</Dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </Row>
    );
  };

  const renderRoom = (room: Room) => {
    return (
      <Row key={`room-${room.id}`}>
        <div className="columns">
          <div className="column">
            <BuildingName href={`/buildings/${room.buildingId}`}>
              {room.buildingName}
            </BuildingName>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-quarters">
            {room.image && <img src={room.image} />}
          </div>
          <div className="column is-three-quarters">
            <div className="columns is-gapless">
              <div className="column">
                <dl className="building-search__building-info-data">
                  <dt>{t("住所")}</dt>
                  <Dd>
                    {room.address}
                    <a
                      href={`/buildings/${room.buildingId}#environment_info`}
                      className="building-search__building-map-link"
                      style={{ color: "#fff" }}
                    >
                      MAP
                    </a>
                  </Dd>
                </dl>
              </div>
              <div className="column">
                <dl className="building-search__building-info-data">
                  <dt>{t("最寄駅")}</dt>
                  <Dd>{nl2br(room.nearestStation)}</Dd>
                </dl>
              </div>
              <div className="column">
                <dl className="building-search__building-info-data">
                  <dt>{t("施工")}</dt>
                  <Dd>{room.age}</Dd>
                </dl>
              </div>
            </div>
            <div className="columns is-gapless">
              <div className="building-search__building-info-table mt-0">
                <div className="building-search__building-info-table-row">
                  <div className="building-search__rooms-info-label">
                    {room.name}
                  </div>
                  <div className="building-search__rooms-info-item">
                    <div className="building-search__rooms-info-col1">
                      <div className="building-search__rooms-info-col1-row1">
                        {t("賃料")}
                      </div>
                    </div>
                    <div className="building-search__rooms-info-col2">
                      <div className="building-search__rooms-info-col2-row1">
                        {room.canShowRent
                          ? `${numeral(room.monthlyRent).format("0,0")}円`
                          : room.monthlyRent}
                        {room.canShowRent && <span>/月</span>}
                      </div>
                    </div>
                    <div className="building-search__rooms-info-col3">
                      <div className="building-search__rooms-info-col3-row1">
                        {t("許容人数")}
                        {room.isVirtual ? "-" : `${room.maxCapacity}名`}
                      </div>
                    </div>
                  </div>
                  <div className="building-search__rooms-info-controls">
                    <div className="building-search__rooms-info-control">
                      <a
                        href={`/buildings/${room.buildingId}/rooms/${room.id}${i18n.language === "ja" ? "" : "?lang=" + i18n.language}`}
                        className="button is-primary building-search__rooms-info-control-button"
                      >
                        {t("詳細を見る")}
                      </a>
                    </div>
                    <div className="building-search__rooms-info-control">
                      {room.isVacant ?
                        <a
                          href={
                            room.canShowRent
                              ? `/entries/new?room_ids=${room.id}${i18n.language === "ja" ? "" : "&lang=" + i18n.language}`
                              : `/entries/new?room_ids=${room.id}&entry_type=3${i18n.language === "ja" ? "" : "&lang=" + i18n.language}`
                          }
                          className={`button ${room.canShowRent ? "is-secondary" : "is-quinary"
                            } building-search__rooms-info-control-button`}
                        >
                          {room.cvButtonLabel}
                        </a>
                        : <button className="button is-warning building-search__rooms-info-control-button" disabled={true}>{t("募集停止中")}</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
    );
  };

  const renderPageHistory = (history: PageHistory) => {
    if (history instanceof Building) {
      return renderBuilding(history);
    } else {
      return renderRoom(history);
    }
  };

  const { items, isFetching, histories } = props.pageHistory;

  return (
    <StyledPopup
      trigger={renderButton()}
      modal={true}
      closeOnDocumentClick={true}
      lockScroll={true}
    >
      {close => (
        <PopupBody>
          <ModalBody>
            {items.length === 0 ? (
              <span>{t("閲覧履歴がありません")}</span>
            ) : isFetching ? (
              <span>{t("取得中です")}</span>
            ) : (
              histories.map(h => renderPageHistory(h))
            )}
          </ModalBody>
          <CloseButton
            className="button is-light is-fullwidth"
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => {
              close();
            }}
          >
            {t("閉じる")}
          </CloseButton>
        </PopupBody>
      )}
    </StyledPopup>
  );
}

const mapStateToProps = (state: AppState) => ({
  pageHistory: state.pageHistory
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  requestFetchPageHistories: () => dispatch(actions.pageHistory.fetch())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PageHistoriesList
);
