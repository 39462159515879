import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import fetchRooms from "../api/rooms/fetchRooms";
import saveFavoriteItems from "../business_logics/saveFavoriteItems";
import Room from "../models/Room";
import * as selectors from "./selectors";

function* saveToCookie() {
  try {
    const favorite = yield select(selectors.getFavorite);
    yield call(saveFavoriteItems, favorite.items);
  } catch (error) {
    yield put(actions.version.receive(error));
  }
}

function* handleFetchRequest() {
  try {
    const favorite = yield select(selectors.getFavorite);
    const data = yield call(fetchRooms, favorite.items);
    const rooms: Room[] = (data as any[]).map(
      d =>
        new Room({
          id: d.building.room.id,
          // tslint:disable-next-line:object-literal-sort-keys
          buildingName: d.building.name,
          buildingId: d.building.id,
          name: d.building.room.name,
          numberOfEmployees: d.building.room.numberOfEmployees,
          monthlyRent: d.building.room.monthlyRent,
          monthlyRentPerPerson: d.building.room.monthlyRentPerPerson,
          initialCost: d.building.room.initialCost,
          floorSpace: d.building.room.floorSpace,
          floorSpacePerPerson: d.building.room.floorSpacePerPerson,
          maxCapacity: d.building.room.maxCapacity,
          image: d.building.room.image,
          canShowRent: d.building.room.canShowRent,
          options: d.building.room.options
        })
    );
    yield put(actions.favorite.receive(rooms));
    yield put(actions.favorite.sort());
  } catch (error) {
    // TODO: implement
  }
}

function* handleOrderChanged() {
  yield put(actions.favorite.sort());
}

function* triggerRemoveEvent() {
  window.dispatchEvent(new Event("jfo/update-fav-item-count"));
}

function* watchFavorite() {
  yield takeLatest(
    [
      actions.favorite.add,
      actions.favorite.bringBackward,
      actions.favorite.bringForward,
      actions.favorite.remove
    ],
    saveToCookie
  );
  yield takeEvery(
    [
      actions.favorite.bringBackward,
      actions.favorite.bringForward,
      actions.favorite.remove
    ],
    handleOrderChanged
  );
  yield takeLatest(actions.favorite.fetch, handleFetchRequest);
  yield takeLatest(actions.favorite.remove, triggerRemoveEvent);
}

export { saveToCookie, handleFetchRequest, watchFavorite };
