import { createStandardAction } from "typesafe-actions";
import Building from "../models/Building";
import { AreaSearchResponse } from "../types/AreaSearchResponse";

const UPDATE_KEYWORD = "KEYWORD_SEARCH/UPDATE_KEYWORD";
const FETCH_BUILDING = "KEYWORD_SEARCH/FETCH_BUILDING";
const RECEIVE_BUILDING = "KEYWORD_SEARCH/RECEIVE_BUILDING";
const FETCH_AREA = "KEYWORD_SEARCH/FETCH_AREA";
const RECEIVE_AREA = "KEYWORD_SEARCH/RECEIVE_AREA";

export const updateKeyword = createStandardAction(UPDATE_KEYWORD)<string>();
export const fetchBuilding = createStandardAction(FETCH_BUILDING)();
export const receiveBuilding = createStandardAction(RECEIVE_BUILDING)<
  Building[]
>();
export const fetchArea = createStandardAction(FETCH_AREA)();
export const receiveArea = createStandardAction(RECEIVE_AREA)<
  AreaSearchResponse
>();
