import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import buildingSearchReducer, {
  BuildingSearchState
} from "../states/buildingSearch";
import favoriteReducer, { FavoriteState } from "../states/favorite";
import keywordSearchReducer, {
  KeywordSearchState
} from "../states/keywordSearch";
import pageHistoryReducer, { PageHistoryState } from "../states/pageHistory";
import versionReducer, { VersionState } from "../states/version";

export interface AppState {
  buildingSearch: BuildingSearchState;
  favorite: FavoriteState;
  pageHistory: PageHistoryState;
  keywordSearch: KeywordSearchState;
  version: VersionState;
}

export default () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares: [any] = [sagaMiddleware];
  if (process.env.NODE_ENV === `development`) {
    middlewares.push(logger);
  }

  const store = createStore(
    combineReducers({
      buildingSearch: buildingSearchReducer,
      favorite: favoriteReducer,
      pageHistory: pageHistoryReducer,
      // tslint:disable-next-line:object-literal-sort-keys
      keywordSearch: keywordSearchReducer,
      version: versionReducer
    }),
    composeWithDevTools(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);

  return store;
};
