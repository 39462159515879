import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions";
import fetchPageHistoryItems from "../business_logics/fetchPageHistoryItems";
import { PageHistory, PageHistoryItem } from "../types/PageHistory";

type Action = ActionType<typeof actions>;

export interface PageHistoryState {
  readonly isFetching: boolean;
  readonly items: PageHistoryItem[];
  readonly histories: PageHistory[];
}

export const initialState: PageHistoryState = {
  isFetching: false,
  items: fetchPageHistoryItems(),
  // tslint:disable-next-line:object-literal-sort-keys
  histories: []
};

export default (state: PageHistoryState = initialState, action: Action) => {
  switch (action.type) {
    case getType(actions.pageHistory.fetch): {
      return { ...state, isFetching: true };
    }
    case getType(actions.pageHistory.receive): {
      return { ...state, histories: action.payload, isFetching: false };
    }
    default:
      return state;
  }
};
