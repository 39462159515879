import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import * as humps from "humps";
import client from "./client";

/**
 * Request Wrapper with default success/error actions
 *
 * @param {object} options - axios options
 */

const request = (options: AxiosRequestConfig) => {
  const converted = options;
  if (converted.data) {
    converted.data = humps.decamelizeKeys(converted.data);
  }
  if (converted.params) {
    converted.params = humps.decamelizeKeys(converted.params);
  }

  const onSuccess = (response: AxiosResponse<any>) =>
    humps.camelizeKeys(response.data);
  const onError = (error: AxiosError) =>
    Promise.reject(error.response || error.message);

  return client(converted)
    .then(onSuccess)
    .catch(onError);
};

export default request;
