import request from "../request";

const fetchAreas = async (q: string) => {
  const url = "/areas";

  return request({
    baseURL: "/api/v1",
    method: "get",
    params: { q },
    url
  });
};
export default fetchAreas;
