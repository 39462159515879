import {forwardRef, InputHTMLAttributes} from "react";
import * as React from "react";

export interface InputImageProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: InputHTMLAttributes<HTMLInputElement>["id"];
}

const InputImage = forwardRef<HTMLInputElement, InputImageProps>(
  ({ onChange, id }, ref) => {
    return (
      <input
        ref={ref}
        id={id}
        type="file"
        accept="image/*"
        onChange={onChange}
        hidden={true}
      />
    );
  }
);

export default InputImage;
