import * as numeral from "numeral";
import Room from "./Room";
import Tag from "./Tag";

export default class Building {
  public id: number = 0;
  public name: string = "";
  public image?: string;
  public address: string = "";
  public latitude?: number;
  public longitude?: number;
  public age: string = "";
  public nearestStation: string = "";
  public rooms: Room[];
  public tags?: Tag[];
  public isPr?: boolean;

  constructor(obj: Partial<Building>) {
    Object.assign(this, obj);
  }

  public get occupyTypes() {
    return Array.from(new Set(this.rooms.map(r => r.occupyType)));
  }

  public get monthlyRent() {
    const rent = this.rooms[0]?.canShowRent
      ? `${numeral(this.rooms[0]?.monthlyRent).format("0,0")}円/月`
      : this.rooms[0]?.monthlyRent!;
    return `${rent || ""}(${this.rooms[0]?.maxCapacity || 1}名)〜`;
  }
}
