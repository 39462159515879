import { createStandardAction } from "typesafe-actions";
import { APIError } from "../api";

const FETCH_LATEST = "VERSION/FETCH_LATEST";
const RECEIVE = "VERSION/RECEIVE";

export interface VersionPayload {
  version: string;
}

export const fetchLatest = createStandardAction(FETCH_LATEST)<void>();
export const receive = createStandardAction(RECEIVE)<
  VersionPayload | APIError | Error
>();
