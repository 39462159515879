import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import ja from "./ja.json";

i18n.use(initReactI18next).init({
  resources: {
    en,
    ja
  },
  // tslint:disable-next-line:object-literal-sort-keys
  lng: /lang=en/.test(window.location.search) ? "en" : "ja",
  fallbackLng: "ja",
  interpolation: {
    // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    escapeValue: false
  }
});

export default i18n;
