import * as queryString from "query-string";
import * as store from "store2";
import Constants from "../constants";

const idsFromQueryParams = () => {
  const params = queryString.parse(window.location.search, {
    arrayFormat: "bracket"
  });
  if (params.room_id) {
    return parseInt(params.room_id as string, 10);
  }
  if (params.room_ids) {
    const roomIds =
      typeof params.room_ids === "string" ? [params.room_ids] : params.room_ids;
    return roomIds.map(id => parseInt(id, 10));
  }
  return null;
};

export default (): number[] =>
  idsFromQueryParams() || store(Constants.KEY_STORED_FAVORITE_ITEMS) || [];
