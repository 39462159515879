import { debounce } from "lodash";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Image as PureReactImage,
  Slide,
  Slider
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import constants from "../../../constants";
import "./style.css";

const Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Column = styled.div`
  height: 298px;
  width: 448px;
`;

const Pager = styled.div<{ pageSize: number }>`
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  color: #fff;
  display: ${({ pageSize }) => (pageSize >= 3 ? "block" : "none")};
  font-size: 0.8rem;
  padding: 2px 4px;
  position: absolute;
  top: 8px;
  right: 56px;
  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
    right: auto;
    left: 8px;
  }
`;

type ImageType = {
  src: string;
  caption: string;
}

interface Props {
  images: ImageType[];
}

const Carousel: React.FunctionComponent<Props> = props => {
  const responsiveSlideCount = () =>
    window.outerWidth >= constants.breakpoints.sm && props.images.length >= 3
      ? 3
      : 1;
  const [page, setPage] = useState(
    window.outerWidth >= constants.breakpoints.sm ? 3 : 1
  );
  const [slideCount, setSlideCount] = useState(responsiveSlideCount());

  const resize = () => {
    setSlideCount(responsiveSlideCount());
  };
  const handleResize = debounce(resize, 50);
  useEffect(() => resize(), []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const nextPage = () => {
    if (page < props.images.length) {
      setPage(page + 1);
    }
  };

  const openModal = (src: string, caption: string) => {
    const imgPreload = new Image();
    imgPreload.src = src;

    const modalId = "modal-" + src.replace(/[^a-zA-Z0-9]/g, "");
    const html =
      '<div class="modal-background"></div>' +
      '<div class="modal-card">' +
      '<div class="modal-card-body"><figure class="image">' +
      `<img src="${src}"></figure>${caption}</div></div>` +
      '<button class="modal-close is-large" aria-label="close"></button>';

    if (document !== null) {
      const div = document.createElement("div");

      div.innerHTML = html;
      div.className = "modal is-active";
      div.id = modalId;
      document.body.appendChild(div);
      document
        .querySelector("#" + modalId + " .modal-close")!
        .addEventListener("click", function () {
          const el = document.getElementById(modalId);
          if (el !== null) {
            el!.parentElement!.removeChild(el);
          }
        });
      document
        .querySelector("#" + modalId + " .modal-background")!
        .addEventListener("click", function () {
          const el = document.getElementById(modalId);
          if (el !== null) {
            el!.parentElement!.removeChild(el);
          }
        });
    }
  };

  return <>
    {page === 3 && props.images.length < 3 ? <Columns>
      {props.images.map((img, i) => (
        <Column key={i}>
          <img src={img.src} alt={img.caption} className="image" onClick={() => openModal(img.src, img.caption)} style={{ height: "100%", width: "auto" }} />
        </Column>
      ))}
    </Columns> : (
      <CarouselProvider
        dragEnabled={false}
        touchEnabled={false}
        naturalSlideWidth={360}
        naturalSlideHeight={240}
        totalSlides={props.images.length}
        visibleSlides={slideCount}
        hasMasterSpinner={true}
      >
        <Slider>
          {props.images.map((img, i) => (
            <Slide index={i} key={i}>
              <div onClick={() => openModal(img.src, img.caption)} style={{ width: "100%", height: "100%" }}>
                <PureReactImage src={img.src} hasMasterSpinner={true} />
              </div>
            </Slide>
          ))}
        </Slider>
        <ButtonBack onClick={prevPage}>&nbsp;</ButtonBack>
        <ButtonNext onClick={nextPage}>&nbsp;</ButtonNext>
        <Pager
          pageSize={props.images.length}
        >{`${page} / ${props.images.length}`}</Pager>
      </CarouselProvider>
    )}
  </>
};

export default Carousel;
