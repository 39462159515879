import { call, put, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import fetchBuildings from "../api/buildings/fetchBuildings";
import fetchRooms from "../api/rooms/fetchRooms";
import Building from "../models/Building";
import Room from "../models/Room";
import { PageHistoryState } from "../states/pageHistory";
import { PageHistory } from "../types/PageHistory";
import * as selectors from "./selectors";

function* handleFetchRequest() {
  try {
    const pageHistory: PageHistoryState = yield select(
      selectors.getPageHistory
    );
    const buildingIds = pageHistory.items
      .filter(item => !item.room)
      .map(item => item.building);
    const roomIds = pageHistory.items
      .filter(item => item.room)
      .map(item => item.room!);

    const buildingsData = yield call(fetchBuildings, buildingIds);
    const roomsData = yield call(fetchRooms, roomIds);

    const initializeRoom = (room: any, building: any) =>
      new Room({
        id: room.id,
        // tslint:disable-next-line:object-literal-sort-keys
        buildingName: building.name,
        buildingId: building.id,
        address: building.address,
        age: building.age,
        nearestStation: building.nearestStation,
        name: room.name,
        numberOfEmployees: room.numberOfEmployees,
        monthlyRent: room.monthlyRent,
        monthlyRentPerPerson: room.monthlyRentPerPerson,
        initialCost: room.initialCost,
        floorSpace: room.floorSpace,
        floorSpacePerPerson: room.floorSpacePerPerson,
        maxCapacity: room.maxCapacity,
        image: room.image,
        canShowRent: room.canShowRent,
        isVacant: room.isVacant,
        isVirtual: room.isVirtual,
        cvButtonLabel: room.cvButtonLabel,
        options: room.options
      });

    const initializeBuilding = (building: any) =>
      new Building({
        id: building.id,
        name: building.name,
        // tslint:disable-next-line:object-literal-sort-keys
        image: building.image,
        address: building.address,
        age: building.age,
        nearestStation: building.nearestStation,
        rooms: building.rooms.map((r: any) => initializeRoom(r.room, building))
      });

    const buildings: Building[] = buildingsData.map((d: any) =>
      initializeBuilding(d.building)
    );
    const rooms: Room[] = roomsData.map((d: any) =>
      initializeRoom(d.building.room, d.building)
    );

    const histories = pageHistory.items
      .map(item => {
        if (item.room) {
          return rooms.find(
            r => r.buildingId === item.building && r.id === item.room
          );
        } else {
          return buildings.find(b => b.id === item.building);
        }
      })
      .filter(h => !!h);

    yield put(actions.pageHistory.receive(histories as PageHistory[]));
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    // TODO: implement
  }
}

function* watchPageHistory() {
  yield takeLatest(actions.pageHistory.fetch, handleFetchRequest);
}

export { handleFetchRequest, watchPageHistory };
