import request from "../request";

const fetchLatest = async () => {
  const url = "/versions/latest";
  return request({
    baseURL: "/api",
    method: "get",
    url
  });
};
export default fetchLatest;
