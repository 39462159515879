import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import { AnyAction, Dispatch } from "redux";
import styled from "styled-components";
import * as actions from "../../actions";
import constants from "../../constants";
import i18n from "../../locales/i18n";
import { KeywordSearchState } from "../../states/keywordSearch";
import { AppState } from "../../store";

const Outside = styled.div`
  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
      background-color: #e4eff3;
      padding: ${(props: { isFullWidth: boolean }) => props.isFullWidth ? "15px 0" : "15px 35px"};
      width: 100%;
  }
`;

const Input = styled.input`
  border-color: #d9e1f1;
  border-radius: 4px;
  font-size: 14px;
  width: 320px;
  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
      border: none;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      width: 100%;
  }
`;

const Box = styled.div`
  color: #212121;
  position: relative;
`;

const Results = styled.div`
  background-color: #fff;
  position: absolute;
  height: auto;
  width: 320px;
  max-height: 480px;
  overflow-y: auto;
  z-index: 10;

  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
      width: 100%;
  }
`;

const Section = styled.div`
  background-color: #e4eff3;
  font-weight: 700;
  padding: 12px 8px;
  width: 100%;
  @media only screen and (max-width: ${constants.breakpoints.sm}px),
    (max-device-width: ${constants.breakpoints.sm}px) {
      background-color: #ededed;
  }
`

const Row = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;

  a {
    color: #212121;
    display: block;
    height: 100%;
    width: 100%;
    padding: 12px 8px;
  }

  a:hover {
    background-color: rgba(228, 239, 243, 0.5);
    text-decoration: underline;
  }
`

interface InnerProps {
  isFullWidth: boolean;
  hasIcon: boolean;
  keywordSearch: KeywordSearchState;
  updateKeyword: (k: string) => void;
}

interface OuterProps {
  isFullWidth: boolean;
  hasIcon: boolean;
}

const KeywordSearchForm: React.FC<InnerProps> = ({ isFullWidth = false, hasIcon = true, keywordSearch, updateKeyword }) => {
  const { t } = useTranslation();
  return (
    <Outside isFullWidth={isFullWidth}>
      <div className="control has-icons-right">
        <Input type="text" className="input" placeholder={t("キーワード検索（エリア名・物件名など）")} onChange={(e) => updateKeyword(e.target.value)} />
        {hasIcon &&
          <span className="icon is-small is-right">
            <i className="fas fa-search" style={{ color: constants.colors.brand }} />
          </span>
        }
      </div>
      {keywordSearch.keyword &&
        <Box>
          <Results>
            {((keywordSearch.area?.areas && keywordSearch.area?.areas.length > 0) || (keywordSearch.area?.wards && keywordSearch.area?.wards.length > 0)) && <Section>
              {t("エリア")}
            </Section>}
            {keywordSearch.area?.areas.map(a => <Row key={`area-${a.link}`}><a href={`${a.link}${i18n.language === "ja" ? "" : "?lang=en"}`}>{i18n.language === "ja" ? a.name : a.nameEn}</a></Row>)}
            {keywordSearch.area?.wards.map(w => <Row key={`ward-${w.link}`}><a href={`${w.link}${i18n.language === "ja" ? "" : "?lang=en"}`}>{i18n.language === "ja" ? w.name : w.nameEn}</a></Row>)}
            {(keywordSearch.buildings.length > 0 || keywordSearch.isFetchingBuilding) && <Section>{t("物件名")}</Section>}
            {keywordSearch.isFetchingBuilding && <Row style={{ textAlign: "center" }}><img src={constants.images.loading} style={{ height: "32px", width: "auto" }} /></Row>}
            {keywordSearch.buildings.map(b => <Row key={`building-${b.id}`}><a href={`/buildings/${b.id}${i18n.language === "ja" ? "" : "?lang=en"}`}>{b.name}</a></Row>)}
          </Results>
        </Box>
      }
    </Outside>
  );
}

const mapStateToProps = (state: AppState) => ({
  keywordSearch: state.keywordSearch
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  updateKeyword: (keyword: string) => dispatch(actions.keywordSearch.updateKeyword(keyword)),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  KeywordSearchForm
);
