import React from "react";
import Cropper, { Area } from "react-easy-crop";
import Modal from 'react-modal';
import styled from "styled-components";

const customStyles = {
  content: {
    top: '50%',
    right: 'auto',
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalBody = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const ModalContainer = styled.div`
  width: 640px;
  height: 320px;
  position: relative;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 16px;
`;


interface Props {
  crop: {
    x: number;
    y: number;
  };
  setCrop: (crop: { x: number; y: number }) => void;
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
  open: boolean;
  onClose: () => void;
  imgSrc: string;
  showCroppedImage: () => void;
  width: number;
  height: number;
}

const CropperModal: React.FC<Props> = ({
   crop,
   setCrop,
   onCropComplete,
   open,
   onClose,
   imgSrc,
   showCroppedImage,
   width,
   height,
}) => {
  return (
    <Modal
      isOpen={open}
      style={customStyles}
      appElement={document.getElementById('root') as HTMLElement}
    >
      <ModalBody>
        <ModalContainer>
          <Cropper
            image={imgSrc}
            crop={crop}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            aspect={width / height}
            // zoom={zoom}
            objectFit={'cover'}
            classes={{
              containerClassName: "container",
              cropAreaClassName: "crop-area",
              mediaClassName: "media"
            }}
            showGrid={true}
          />
        </ModalContainer>
        <ButtonGroup>
          <button className="button mr-1" onClick={onClose}>
            キャンセル
          </button>
          <button
            className="button is-primary"
            onClick={() => {
              onClose();
              showCroppedImage();
            }}
          >
            保存
          </button>
        </ButtonGroup>
      </ModalBody>
    </Modal>
  );
};
export default CropperModal;
