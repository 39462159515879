import * as cookies from "js-cookie";
import { isEqual, uniqWith } from "lodash";
import Constants from "../constants";
import { PageHistoryItem } from "../types/PageHistory";
import fetchPageHistoryItems from "./fetchPageHistoryItems";

export default (history: PageHistoryItem) => {
  const histories = fetchPageHistoryItems();
  cookies.set(
    Constants.KEY_STORED_PAGE_HISTORIES,
    JSON.stringify(uniqWith([history, ...histories], isEqual).slice(0, 4)),
    { expires: 365 }
  );
};
