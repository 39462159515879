import { shuffle, take } from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import searchBuildings from "../api/buildings/searchBuildings";
import Building from "../models/Building";
import Room from "../models/Room";
import Tag from "../models/Tag";
import { BuildingItem } from "../types/BuildingItem";

function* handleFetchRequest() {
  try {
    const result: BuildingItem = yield call(searchBuildings);

    const initializeTag = (tag: any) =>
      new Tag({
        name: tag.name,
        url: tag.url
      });

    const initializeRoom = (room: any, building: any) =>
      new Room({
        id: room.id,
        // tslint:disable-next-line:object-literal-sort-keys
        buildingName: building.name,
        buildingId: building.id,
        address: building.address,
        age: building.age,
        nearestStation: building.nearestStation,
        name: room.name,
        numberOfEmployees: room.numberOfEmployees,
        monthlyRent: room.monthlyRent,
        monthlyRentPerPerson: room.monthlyRentPerPerson,
        initialCost: room.initialCost,
        floorSpace: room.floorSpace,
        floorSpacePerPerson: room.floorSpacePerPerson,
        occupyType: room.occupyType,
        maxCapacity: room.maxCapacity,
        image: room.image,
        canShowRent: room.canShowRent,
        isVirtual: room.isVirtual,
        cvButtonLabel: room.cvButtonLabel,
        options: room.options
      });

    const initializeBuilding = (building: any) =>
      new Building({
        id: building.id,
        name: building.name,
        // tslint:disable-next-line:object-literal-sort-keys
        image: building.image,
        address: building.address,
        latitude: building.latitude,
        longitude: building.longitude,
        age: building.age,
        nearestStation: building.nearestStation,
        rooms: building.rooms.map((r: any) => initializeRoom(r.room, building)),
        tags: building.tags.map((t: any) => initializeTag(t)),
        isPr: building.isPr
      });

    const buildings: Building[] = result.buildings.map((b: any) =>
      initializeBuilding(b)
    );
    const prBuildings: Building[] = result.prBuildings.map((b: any) =>
      initializeBuilding(b)
    );

    yield put(
      actions.buildingSearch.receive({
        buildings,
        prBuildings: take(shuffle(prBuildings), 3),
        // tslint:disable-next-line:object-literal-sort-keys
        pager: result.pager
      })
    );
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    // TODO: implement
  }
}

function* watchBuildingSearch() {
  yield takeLatest(actions.buildingSearch.fetch, handleFetchRequest);
}

export { handleFetchRequest, watchBuildingSearch };
