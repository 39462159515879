import { createStandardAction } from "typesafe-actions";
import Building from "../models/Building";
import { BuildingItem } from "../types/BuildingItem";

const FETCH = "BUILDING_SEARCH/FETCH";
const RECEIVE = "BUILDING_SEARCH/RECEIVE";
const ACTIVATE = "BUILDING_SEARCH/ACTIVATE";

export const fetch = createStandardAction(FETCH)();
export const receive = createStandardAction(RECEIVE)<BuildingItem>();
export const activate = createStandardAction(ACTIVATE)<Building | undefined>();
