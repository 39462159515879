import * as React from "react";
import { compose, pure, setDisplayName } from "recompose";
import styled from "styled-components";
import Room from "../../../models/Room";
import FavoriteRoomsSimpleListItem from "../../molecules/FavoriteRoomsSimpleListItem";

const Wrap = styled.div`
  max-height: 730px;
  overflow-y: auto;
`;

interface Props {
  rooms: Room[];
}

const FavoriteRoomsSimpleList: React.FunctionComponent<Props> = props => {
  return (
    <Wrap>
      {props.rooms.map((room) => <FavoriteRoomsSimpleListItem key={`${room.buildingId}-${room.id}`} room={room} />)}
    </Wrap>
  );
};

export default compose<Props, Props>(
  pure,
  setDisplayName("FavoriteRoomsSimpleList")
)(FavoriteRoomsSimpleList);
