import request from "../request";

const fetchRooms = async (roomIds: number[]) => {
  const url = "/rooms";
  return request({
    baseURL: "/api/v1",
    method: "get",
    params: {
      ids: roomIds,
      lang: /lang=en/.test(window.location.search) ? "en" : "ja"
    },
    url
  });
};
export default fetchRooms;
