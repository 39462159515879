import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions";
import Building from "../models/Building";
import { AreaSearchResponse } from "../types/AreaSearchResponse";

type Action = ActionType<typeof actions>;

export interface KeywordSearchState {
  readonly isFetchingBuilding: boolean;
  readonly isFetchingArea: boolean;
  readonly keyword: string;
  readonly buildings: Building[];
  readonly area: AreaSearchResponse | null;
}

export const initialState: KeywordSearchState = {
  isFetchingBuilding: false,
  // tslint:disable-next-line:object-literal-sort-keys
  isFetchingArea: false,
  keyword: "",
  // tslint:disable-next-line:object-literal-sort-keys
  buildings: [],
  area: null
};

export default (state: KeywordSearchState = initialState, action: Action) => {
  switch (action.type) {
    case getType(actions.keywordSearch.updateKeyword): {
      return { ...state, keyword: action.payload };
    }
    case getType(actions.keywordSearch.fetchBuilding): {
      return { ...state, buildings: [], isFetchingBuilding: true };
    }
    case getType(actions.keywordSearch.fetchArea): {
      return { ...state, area: null, isFetchingArea: true };
    }
    case getType(actions.keywordSearch.receiveBuilding): {
      return { ...state, buildings: action.payload, isFetchingBuilding: false };
    }
    case getType(actions.keywordSearch.receiveArea): {
      return { ...state, area: action.payload, isFetchingArea: false };
    }
    default:
      return state;
  }
};
