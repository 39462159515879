import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: #eaeff6;
  padding: 10px;
`;

const Description = styled.div`
  background-color: #fff;
  font-weight: 600;
  padding: 20px;
  text-align: center;
`;

const Text = styled.div`
  font-size: 14px;
  padding: 20px 20px 10px;
`;

const EmptyFavoriteRooms: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Description>
        <span>{t("現在、検討リストに")}</span>
        <br />
        <span>{t("登録されている")}</span>
        <br />
        <span>{t("オフィスはございません")}</span>
      </Description>
      <Text>{t("検討リストに登録すると、いつでもリストから簡単に比較できるようになります。")}</Text>
    </Wrapper>
  );
};

export default EmptyFavoriteRooms;
