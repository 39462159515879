import { call, debounce, put, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import fetchAreas from "../api/areas/fetchAreas";
import fetchBuildingsByName from "../api/buildings/fetchBuildingsByName";
import Building from "../models/Building";
import { KeywordSearchState } from "../states/keywordSearch";
import { AreaSearchResponse } from "../types/AreaSearchResponse";
import * as selectors from "./selectors";

function* handleUpdateKeyword() {
  yield put(actions.keywordSearch.fetchBuilding());
  yield put(actions.keywordSearch.fetchArea());
}

function* watchUpdateKeyword() {
  yield debounce(500, actions.keywordSearch.updateKeyword, handleUpdateKeyword);
}

function* watchFetchBuilding() {
  yield takeLatest(actions.keywordSearch.fetchBuilding, handleFetchBuilding);
}

function* handleFetchBuilding() {
  try {
    const keywordSearch: KeywordSearchState = yield select(
      selectors.getKeywordSearch
    );
    const buildingsData: unknown[] = yield call(
      fetchBuildingsByName,
      keywordSearch.keyword
    );

    const initializeBuilding = (building: any) =>
      new Building({
        id: building.id,
        name: building.name
      });

    const buildings: Building[] = buildingsData.map((d: any) =>
      initializeBuilding(d.building)
    );

    yield put(actions.keywordSearch.receiveBuilding(buildings));
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    // TODO: implement
  }
}

function* handleFetchArea() {
  try {
    const keywordSearch: KeywordSearchState = yield select(
      selectors.getKeywordSearch
    );
    const data: AreaSearchResponse = yield call(
      fetchAreas,
      keywordSearch.keyword
    );
    yield put(actions.keywordSearch.receiveArea(data));
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    // TODO: implement
  }
}

function* watchFetchArea() {
  yield takeLatest(actions.keywordSearch.fetchArea, handleFetchArea);
}

export { watchUpdateKeyword, watchFetchBuilding, watchFetchArea };
