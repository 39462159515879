import { all } from "redux-saga/effects";
import { watchBuildingSearch } from "./buildingSearch";
import { watchFavorite } from "./favorite";
import {
  watchFetchArea,
  watchFetchBuilding,
  watchUpdateKeyword
} from "./keywordSearch";
import { watchPageHistory } from "./pageHistory";
import { watchVersionRequest } from "./version";

export default function* rootSaga() {
  yield all([
    watchBuildingSearch(),
    watchFavorite(),
    watchPageHistory(),
    watchUpdateKeyword(),
    watchFetchBuilding(),
    watchFetchArea(),
    watchVersionRequest()
  ]);
}
