import { DragEvent, FC, ReactNode } from "react";
import * as React from "react";

interface Props {
  children: ReactNode;
  onDropFile: (file: File) => void;
}

const DropImageZone: FC<Props> = ({ onDropFile, children }) => {
  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files !== null && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files.length === 1) {
        onDropFile(e.dataTransfer.files[0]);
      } else {
        alert("画像は1枚のみです");
      }
      e.dataTransfer.clearData();
    }
  };

  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDrop={onDrop}
    >
      {children}
    </div>
  );
};

export default DropImageZone
