import request from "../request";

const searchBuildings = async () => {
  const url = window.location.href.replace("/map", ".json");

  return request({
    method: "get",
    url
  });
};
export default searchBuildings;
