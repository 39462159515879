import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions";
import Building from "../models/Building";
import { Pager } from "../types/Pager";

type Action = ActionType<typeof actions>;

export interface BuildingSearchState {
  readonly isFetching: boolean;
  readonly buildings: Building[];
  readonly prBuildings: Building[];
  readonly activeBuilding?: Building;
  readonly pager?: Pager;
}

export const initialState: BuildingSearchState = {
  isFetching: false,
  // tslint:disable-next-line:object-literal-sort-keys
  buildings: [],
  prBuildings: [],
  activeBuilding: undefined,
  pager: undefined
};

export default (state: BuildingSearchState = initialState, action: Action) => {
  switch (action.type) {
    case getType(actions.buildingSearch.fetch): {
      return { ...state, isFetching: true };
    }
    case getType(actions.buildingSearch.receive): {
      return { ...state, ...action.payload, isFetching: false };
    }
    case getType(actions.buildingSearch.activate): {
      return { ...state, activeBuilding: action.payload };
    }
    default:
      return state;
  }
};
