import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions";
import { VersionPayload } from "../actions/version";
import { APIError } from "../api";

type Action = ActionType<typeof actions>;

export interface VersionState {
  requesting: boolean;
  version: string;
  error: null | APIError | Error;
}

export const initialState: VersionState = {
  error: null,
  requesting: false,
  version: "v0"
};

export default (state: VersionState = initialState, action: Action) => {
  switch (action.type) {
    case getType(actions.version.fetchLatest):
      return { ...state, requesting: true };
    case getType(actions.version.receive):
      const isVersionPayload = (arg: any): arg is VersionPayload =>
        arg.version !== undefined;
      if (isVersionPayload(action.payload)) {
        return { ...state, version: action.payload.version, requesting: false };
      } else {
        return { ...state, error: action.payload, requesting: false };
      }
    default:
      return state;
  }
};
