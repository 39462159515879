import * as React from "react";
import { Provider } from "react-redux";
import store from "../../store";
import Form from "./form";

interface Props {
  isFullWidth: boolean;
  hasIcon: boolean;
}

const KeywordSearch: React.FC<Props> = ({ isFullWidth = false, hasIcon = true }) => {
  return (
    <Provider store={store()}>
      <Form isFullWidth={isFullWidth} hasIcon={hasIcon} />
    </Provider>
  );
}

export default KeywordSearch;
