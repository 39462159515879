const KEY_STORED_FAVORITE_ITEMS = "JFO_FAVORITE_ITEMS";
const KEY_STORED_PAGE_HISTORIES = "JFO_PAGE_HISTORIES";
const KEY_STORED_LAST_ACCESSED_AT = "JFO_LAST_ACCESSED_AT";
const KEY_STORED_ACCESS_COUNT = "JFO_ACCESS_COUNT";

const GOOGLE_MAPS_CUSTOM_STYLE = [
  {
    featureType: "landscape",
    // tslint:disable-next-line:object-literal-sort-keys
    elementType: "geometry",
    stylers: [{ lightness: 25 }]
  },
  { featureType: "poi", elementType: "geometry", stylers: [{ lightness: 25 }] },
  {
    featureType: "poi",
    // tslint:disable-next-line:object-literal-sort-keys
    elementType: "labels.icon",
    stylers: [{ color: "#d2d2d2" }]
  },
  {
    featureType: "poi",
    // tslint:disable-next-line:object-literal-sort-keys
    elementType: "labels.text.fill",
    stylers: [{ color: "#949499" }]
  },
  { featureType: "road", stylers: [{ lightness: 25 }] },
  {
    featureType: "road",
    // tslint:disable-next-line:object-literal-sort-keys
    elementType: "labels",
    stylers: [{ saturation: -100 }, { lightness: 40 }]
  },
  {
    featureType: "transit.station.airport",
    // tslint:disable-next-line:object-literal-sort-keys
    elementType: "geometry",
    stylers: [{ lightness: 25 }]
  },
  {
    featureType: "water",
    // tslint:disable-next-line:object-literal-sort-keys
    elementType: "geometry",
    stylers: [{ lightness: 25 }]
  }
];

const breakpoints = {
  sm: 768,
  // tslint:disable-next-line:object-literal-sort-keys
  md: 1024
};

const colors = {
  brand: "#0a3589",
  mainColor: "#003f96",
  // tslint:disable-next-line:object-literal-sort-keys
  darkBlue: "#05328b"
};

const images = {
  loading:
    "https://jfo-app.s3-ap-northeast-1.amazonaws.com/assets/images/loading.svg"
};

export default {
  KEY_STORED_ACCESS_COUNT,
  KEY_STORED_FAVORITE_ITEMS,
  KEY_STORED_LAST_ACCESSED_AT,
  KEY_STORED_PAGE_HISTORIES,
  // tslint:disable-next-line:object-literal-sort-keys
  GOOGLE_MAPS_CUSTOM_STYLE,
  breakpoints,
  colors,
  images
};
